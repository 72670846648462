<template>
  <div class="prop-calculator">
    <h3>Estimate your change in taxes.</h3>
    <form>
      <div class="flex-d my-5">
        <div class="row">
          <h5>1. Provide Your Property's Value</h5>
          <div class="col">
            <input
              type="number"
              class="form-control"
              id="propertyValue"
              @change="calculate"
              v-model="propertyValue"
              placeholder=""
              aria-label="your proerty's value"
            />
          </div>
        </div>
      </div>
      <div class="flex-d my-5">
        <h5>2. Select Projects You're Interested In.</h5>
        <div class="row justify-content-center">
          <div class="col-auto poo">
            <input
              type="checkbox"
              class="btn-check"
              id="btn-propA"
              autocomplete="off"
              @change="calculate"
              v-model="propA"
            />
            <label class="btn btn-outline-primary" for="btn-propA"
              ><span>Prop A</span> Veterans Memorial</label
            >
          </div>
          <div class="col-auto poo">
            <input
              type="checkbox"
              class="btn-check"
              id="btn-propB"
              autocomplete="off"
              @change="calculate"
              v-model="propB"
            />
            <label class="btn btn-outline-primary" for="btn-propB"
              ><span>Prop B</span>Recreation Center &amp; Library</label
            >
          </div>
          <div class="col-auto poo">
            <input
              type="checkbox"
              class="btn-check"
              id="btn-propC"
              autocomplete="off"
              @change="calculate"
              v-model="propC"
            />
            <label class="btn btn-outline-primary" for="btn-propC"
              ><span>Prop C</span> Parks &amp; Athletic Complexes</label
            >
          </div>
          <div class="col-auto poo">
            <input
              type="checkbox"
              class="btn-check"
              id="btn-propD"
              autocomplete="off"
              @change="calculate"
              v-model="propD"
            />
            <label class="btn btn-outline-primary" for="btn-propD"
              ><span>Prop D</span> Linear Park Trail Network</label
            >
          </div>
          <div class="col-auto poo">
            <input
              type="checkbox"
              class="btn-check"
              id="btn-propE"
              autocomplete="off"
              @change="calculate"
              v-model="propE"
            />
            <label class="btn btn-outline-primary" for="btn-propE"
              ><span>Prop E</span> Miracle League Field</label
            >
          </div>
        </div>
      </div>
      <div class="estimated-change flex my-5">
        <h5>3. See the change*</h5>
        <div v-if="estimatedChange > 0">
          <h3 class="my-0">{{ dollars(estimatedMonthly) }} / month</h3>
          <span>{{ dollars(estimatedChange) }} / year</span>
        </div>
        <div v-else>
          <h3 class="my-0">-</h3>
          <span>&nbsp;</span>
        </div>
        <p class="my-4">
          <sup>*</sup> All amounts shown are an estimation and are subject to
          vary. Other factors like increases in appraised value and exemptions
          provided by your county's tax assessor are not included in these
          estimations.
        </p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      propertyValue: '',
      propA: false,
      propB: false,
      propC: false,
      propD: false,
      propE: false,
      estimatedChange: 0,
      estimatedMonthly: 0,
      formattedValue: '',
    };
  },
  methods: {
    calculate() {
      if (this.propertyValue > 0) {
        // rates are per 100k value per month
        var rateTotal = 0;
        this.propA ? (rateTotal += 0.0057) : false;
        this.propB ? (rateTotal += 0.0645) : false;
        this.propC ? (rateTotal += 0.0455) : false;
        this.propD ? (rateTotal += 0.0086) : false;
        this.propE ? (rateTotal += 0.0041) : false;
        // now calculate the rate
        this.estimatedChange = (this.propertyValue / 100) * rateTotal;
        this.estimatedMonthly = this.estimatedChange / 12;
      } else {
        // show error on property value
      }
    },
    dollars(value) {
      return `$${value.toFixed(2)}`;
    },
    formatValue() {
      //   var valueString = this.propertyValue;
      //   if (typeof valueString != 'string') {
      //     valueString = valueString.toString();
      //   }
      //   const x = valueString
      //     .replace(/\D/g, '')
      //     .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      //   console.log(x.length, x);
      //   if (!x[2]) {
      //     console.log('no thousands');
      //   } else {
      //     console.log('we have thousands');
      //   }
      var value = this.propertyValue;
      console.log(value);
      if (typeof value !== 'number') {
        // remove formatting
        console.log('remove formatting', value, this.formattedValue);
        value = value.replace(/\D/g, '');
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      this.propertyValue = formatter.format(value);
      console.log(value);
      //const final = x[3] ? x[3] : '';
      //   this.propertyValue = !x[2] ? x[1] : `$${x[1]},${x[2]}`;
    },
  },
};
</script>

<style>
.prop-calculator {
  max-width: 35em;
  margin: 1em auto;
}
@media screen and (max-width: 500px) {
  .prop-calculator {
    width: 90vw;
  }
}
.col {
  text-align: left;
}
.btn-outline-primary {
  border-color: #1a1a1a;
  color: #1a1a1a;
  line-height: 1.2;
  margin: 0.5em 0.25em;
}
.btn-check:checked + .btn-outline-primary,
.btn-check:hover + .btn-outline-primary {
  background-color: #1c3557;
  border-color: #1c3557;
}
@media screen and (hover: none) {
  .btn-check:hover:not(:checked) + .btn-outline-primary {
    background-color: #fff;
    border-color: #1a1a1a;
    color: #1a1a1a;
  }
}
.btn-outline-primary span {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
}
.form-control {
  border-color: #999;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
